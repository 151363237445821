<template>
  <span class="time-banner">
    <span
      v-if="include.includes('hours')"
      class="time-banner__dial"
      @click="emit('update', 'hours')"
      v-class-mod:time-banner__dial="{ isActive: dial === 'hours' }"
    >
      {{ time.format("HH") }}
    </span>
    <template v-if="include.includes('hours') && include.includes('minutes')">
      <span class="time-banner__seperator">:</span>
      <span
        class="time-banner__dial"
        @click="emit('update', 'minutes')"
        v-class-mod:time-banner__dial="{ isActive: dial === 'minutes' }"
      >
        {{ time.format("mm") }}
      </span>
    </template>
    <span
      v-else-if="include.includes('minutes')"
      class="time-banner__dial"
      @click="emit('update', 'minutes')"
      v-class-mod:time-banner__dial="{ isActive: dial === 'minutes' }"
    >
      {{ time.format("HH") }}
    </span>
    <template v-if="include.includes('minutes') && include.includes('seconds')">
      <span class="time-banner__seperator">:</span>
      <span
        class="time-banner__dial"
        @click="emit('update', 'seconds')"
        v-class-mod:time-banner__dial="{ isActive: dial === 'seconds' }"
      >
        {{ time.format("mm") }}
      </span>
    </template>
    <span
      v-else-if="include.includes('seconds')"
      class="time-banner__dial"
      @click="emit('update', 'seconds')"
      v-class-mod:time-banner__dial="{ isActive: dial === 'seconds' }"
    >
      {{ time.format("ss") }}
    </span>
  </span>
</template>

<script setup lang="ts">
import { DateTime } from "@horizon56/time";

import { Dial } from "./dial-selector.vue";

withDefaults(
  defineProps<{
    time: DateTime;
    dial: Dial;
    include?: Dial[];
  }>(),
  {
    include: () => ["hours", "minutes"],
  },
);
const emit = defineEmits<{ (e: "update", a: Dial): void }>();
</script>

<style lang="scss" scoped>
.time-banner {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;
  font-weight: 700;
  font-size: var(--app-font-size-h1);
  color: var(--black-28);
  height: var(--app-input-label-height);
  &__seperator {
    margin: 0 5px;
  }
  &__dial {
    cursor: pointer;
    &:hover:not(#{&}--isActive) {
      color: var(--black-50);
    }
    &--isActive {
      color: var(--black-90);
    }
  }
}
</style>
